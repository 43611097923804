import React from "react";
import { SiGmail } from "react-icons/si";
import { FaPhoneAlt } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";
function Footer() {
  return (
    <div
      className="row col-lg-12 p justify-content-between"
      style={{ marginTop: "150px" }}
    >
      <div className="col-lg-3">
        <div>
        
        </div>
        {/* <div style={{ display: "flex", gap: "10px", paddingTop: "25px" }}>
          <a className="span-b" href="mailto:contact@xyz.network">
            <SiGmail />
          </a>
          <span className="span-b">
            <FaPhoneAlt />
          </span>
          <span className="span-b">
            <FaAddressBook />
          </span>
        </div> */}
      </div>
      <div className="col-lg-3 footer">
        {/* <a href="#about">About</a>
        <a href="#service">Service</a>
        <a href="#eco">Blog</a> */}
        {/* <a href=""></a> */}
        {/* <a className="text-info" href="">Staking</a>
        <a className="text-info" href="https://harvestusdt.com/">Harvest Usdt</a> */}
        {/* <div style={{ display: "flex", gap: "10px", paddingTop: "25px" }}>
          <a className="span-b" href="mailto:contact@xyz.network">
            <SiGmail />
          </a>
          <span className="span-b">
            <FaPhoneAlt />
          </span>
          <span className="span-b">
            <FaAddressBook />
          </span>
        </div> */}
      </div>
        
      {/* <div
        className="text-center mt-4 mb-3"
        style={{ color: "#3ce83c", fontWeight: "300" }}
      >
        <u> Design & Developed By Chain Magician Lab Private Limited</u>
      </div> */}
      
    </div>
  );
}

export default Footer;

import { ConnectButton } from "@rainbow-me/rainbowkit";
import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <section className="">
      <div className="header p-2">
        <Link to="/">
          <div className="logo" style={{ width: "100px", height: "50" }}>
            <img
              src="https://harvestusdt.com/images/logo1.png"
              alt=""
              srcset=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Link>
        <div className="menu">
          {/* <Link to="/">Home</Link>
          <Link to="/team">Team</Link>
          <Link to="/service">Service</Link>
          <Link to="/token">User Details</Link>
          <Link to="/reward">Referral</Link>
          <a href="#faq">FAQS</a> */}
               <div className="connect ">
              {/* <button className="btn btn1"> */}
              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
                }) => {
                  const ready = mounted && authenticationStatus !== "loading";
                  const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                      authenticationStatus === "authenticated");

                  return (
                    <div
                      {...(!ready && {
                        "aria-hidden": true,
                        style: {
                          opacity: 0,
                          pointerEvents: "none",
                          userSelect: "none",
                        },
                      })}
                      style={{ width: "100%" }}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <div
                              onClick={openConnectModal}
                              style={{ width: "100%" }}
                              className="btn btn1"
                            >
                              <span>Connect Wallet</span>
                            </div>
                          );
                        }

                        if (chain.unsupported) {
                          return (
                            <div onClick={openChainModal} className="btn btn1">
                              Wrong network
                            </div>
                          );
                        }

                        return (
                          <div
                            style={{ display: "flex", gap: 12 }}
                            className="btn btn1"
                            onClick={openAccountModal}
                          >
                            <div>{account.displayName}</div>
                          </div>
                        );
                      })()}
                    </div>
                  );
                }}
              </ConnectButton.Custom>{" "}
              {/* </button> */}
              {/* <button
                className="btn d-flex justify-content-center align-item-center"
                style={{ gap: "5px" }}
                onClick={stake}
              >
                <img
                  src="https://sale.starchain.dev/assets/images/svg-icons/usdt.svg"
                  alt=""
                  style={{ height: "18px" }}
                />
                <span>Buy With USDT</span>
              </button> */}
            </div>
        </div>
        {/* <div className="presale-btn">
          <button className="btn">Join Presale</button>
        </div> */}
      </div>

      <nav class="navbar pt-4 navbar-dark bg-dark fixed-top">
        <div class="container-fluid">
          <div className="logo" >
            <img
              src="https://harvestusdt.com/images/logo1.png"
              alt=""
              srcset=""
              style={{ maxWidth:"100px" }}
            />
          </div>
          {/* <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button> */}
          <div
            class="offcanvas offcanvas-end text-bg-dark bg-dark"
            tabindex="-1"
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div class="offcanvas-header">
              <div></div>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            {/* <div class="offcanvas-body">
              <ul>
                <li
                  className="menu"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/" className="text-white">
                    Home
                  </Link>
                </li>
                <li
                  className="menu"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/team" className="text-white">
                    Team
                  </Link>
                </li>
                <li
                  className="menu"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/service" className="text-white">
                    Service
                  </Link>
                </li>
                <li
                  className="menu"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/token" className="text-white">
                    User Details
                  </Link>
                </li>
                <li className="menu">
                  <Link
                    to="/reward"
                    className="text-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Referral
                  </Link>
                </li>
                <li></li>
              </ul>
            </div> */}
          </div>
        </div>
      </nav>
    </section>
  );
}

export default Navbar;

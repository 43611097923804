import React from "react";
import {  Anxt } from "./config";
import { FaCopy } from "react-icons/fa";
import toast from "react-hot-toast";

function Address() {
  const copyText = async (textToCopy) => {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Address Copied");
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
        toast.success("Referral Copied");
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  };

  return (
    <div className="row col-lg-12 mt-3 p">
      <div className="address p-4">
        <div
          className="text-white"
          style={{ fontSize: "20px", fontFamily: "sans-serif" }}
        >
          Harvest Token Address
        </div>
        <div className="row col-lg-12 justify-content-between">
          <span style={{ width: "fit-content", padding: "0px",fontFamily: "sans-serif" }} className="add">
            {Anxt}
          </span>
          <span
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={() => {
              copyText(Anxt);
            }}
            className="add1"
          >
            <FaCopy className="text-white" />
          </span>
        </div>
      </div>

            

    </div>
  );
}

export default Address;
